<template>
   <div class="row">
      <div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Emita operações de entradas/saídas não fiscais de estoques e lojas definidos. Essas transações podem ser visualizadas na tela "Entradas e saídas", de forma compartilhada com operações fiscais.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

		<div id="estoque-tab">
			<div class="row">
				<div id="search-nav" class="col-12 px-0 d-block btn-group dropup">
					<button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
						<i class="far fa-file font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
					</button>
					<div class="card mb-1 dropdown-menu dropdown-menu-left">
						<div class="card-body p-3 pb-0">
							<div class="row">
								<div class="col-12" :class="pesquisa.idOperacao != null && (pesquisa.idOperacao.apenasEntrada != true || pesquisa.idOperacao.apenasSaida != true) ? '' : 'mb-3'">
									<label><i class="far fa-stream color-theme font-11 me-1"></i> Operação *</label>
									<v-select name="Desktop" :options="listaOperacoesEstoque" v-model="pesquisa.idOperacao" label="nome" :clearable="false" placeholder="Selecione uma opção" @input="salvarTemp">
										<template #option="option">
											<span class="d-block lh-sm" :class="option.apenasEntrada || option.apenasSaida ? 'mt-1' : 'my-1'">{{ option.nome }}</span>
											<small class="d-block lh-sm mb-1 opacity-90 font-11" v-if="option.apenasEntrada"><i class="far fa-dolly font-9 me-1"></i>Apenas entrada</small>
											<small class="d-block lh-sm mb-1 opacity-90 font-11" v-else-if="option.apenasSaida"><i class="far fa-shopping-cart font-9 me-1"></i>Apenas saída</small>
										</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Operações" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.idOperacao" @change="salvarTemp">
										<option v-for="(option, index) in listaOperacoesEstoque" :key="index" :value="option">{{ option.nome }}</option>
									</select>
								</div>
								<div class="col-12 my-1" v-if="pesquisa.idOperacao != null && pesquisa.idOperacao.apenasEntrada != true"><hr></div>
								<div class="col-sm-6 col-lg-12 mb-3 mb-sm-0 mb-lg-3" v-if="pesquisa.idOperacao != null && pesquisa.idOperacao.apenasEntrada != true">
									<label><i class="far fa-store color-theme font-10 me-1"></i> Loja emitente *</label>
									<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.idLojaSaida" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Selecione uma opção"
										@input="buscarEstoques(pesquisa.idLojaSaida, 'saida')">
										<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.idLojaSaida" @change="buscarEstoques(pesquisa.idLojaSaida, 'saida')">
										<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
									</select>
								</div>
								<div class="col-sm-6 col-lg-12" v-if="pesquisa.idOperacao != null && pesquisa.idOperacao.apenasEntrada != true" :class="pesquisa.idOperacao.apenasSaida != true ? '' : 'mb-3'">
									<label><i class="far fa-box color-theme font-10 me-1"></i> Estoque saída *</label>
									<v-select name="Desktop" :options="estoques.saida" v-model="pesquisa.idEstoqueSaida" label="nome" :reduce="e => e.id" placeholder="Selecione uma opção" @input="salvarTemp">
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.idEstoqueSaida" @change="salvarTemp">
										<option v-for="(option, index) in estoques.saida" :key="index" :value="option.id">{{ option.nome }}</option>
									</select>
								</div>
								<div class="col-12 my-1" v-if="pesquisa.idOperacao != null && pesquisa.idOperacao.apenasSaida != true"><hr></div>
								<div class="col-sm-6 col-lg-12 mb-3" v-if="pesquisa.idOperacao != null && pesquisa.idOperacao.apenasSaida != true">
									<label><i class="far fa-store color-theme font-10 me-1"></i> Loja destinatário *</label>
									<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.idLojaEntrada" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Selecione uma opção"
										@input="buscarEstoques(pesquisa.idLojaEntrada, 'entrada')">
										<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.idLojaEntrada" @change="buscarEstoques(pesquisa.idLojaEntrada, 'entrada')">
										<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
									</select>
								</div>
								<div class="col-sm-6 col-lg-12 mb-3" v-if="pesquisa.idOperacao != null && pesquisa.idOperacao.apenasSaida != true">
									<label><i class="far fa-box color-theme font-10 me-1"></i> Estoque entrada *</label>
									<v-select name="Desktop" :options="estoques.entrada" v-model="pesquisa.idEstoqueEntrada" label="nome" :reduce="e => e.id" placeholder="Selecione uma opção" @input="salvarTemp">
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.idEstoqueEntrada" @change="salvarTemp">
										<option v-for="(option, index) in estoques.entrada" :key="index" :value="option.id">{{ option.nome }}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-0">
					<div class="card mb-1">
						<div class="card-body py-12">
							<div class="row align-items-center">
								<div class="col-6 col-md-3 col-lg-6">
									<strong class="subtitle font-14 me-1">Total</strong>
								</div>
								<div class="col-6 col-md-3 col-lg-6 text-end text-md-start text-lg-end">
									<span class="font-15"><small>R$ </small>{{ parseFloat(pesquisa.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
								</div>
								<div class="col ps-0 pe-1 mt-2 mt-md-0 mt-lg-2" @click="excluirTemp" v-if="pesquisa.idOperacao != null">
									<button class="btn border-red text-red"><i class="fal fa-times me-1"></i> Descartar</button>
								</div>
								<div class="col-6 col-md-3 col-lg-6 px-0 mt-2 mt-md-0 mt-lg-2" @click="emitirEntradaSaida" v-if="pesquisa.produtos.length > 0">
									<button class="btn border-green text-green"><i class="fal fa-save me-1 font-12"></i> Salvar</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg px-lg-0">
			<div class="row">
				<div class="col-12 px-0" v-if="pesquisa.idOperacao && (pesquisa.idLojaEntrada || pesquisa.idLojaSaida)">	
					<div class="card mb-1">
						<div class="card-body p-3 pb-0">
							<div class="row">
								<div class="col-md-6 mb-3">
									<label><i class="far fa-apple-alt color-theme font-11 me-1"></i> Adicionar produto *</label>
									<autoCompleteProduto :loja="pesquisa.idLojaEntrada != null ? pesquisa.idLojaEntrada : pesquisa.idLojaSaida" @submit="setProdutoTemp($event)" />
								</div>
								<div class="col-5 col-md-2 mb-3">
									<label><i class="far fa-box color-theme font-10 me-1"></i> Quantidade *</label>
									<input type="text" class="form-control text-end" ref="pesquisaProdutoQtd" v-model="pesquisaProduto.quantidade" @keyup.enter="$refs.pesquisaProdutoCusto.focus()" />
								</div>
								<div class="col-5 col-md-2 mb-3">
									<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Preço Un. *</label>
									<input type="text" class="form-control text-end" ref="pesquisaProdutoCusto" v-model="pesquisaProduto.preco" @keyup.enter="salvarProdutoTemp(null)" />
								</div>
								<div class="col-2 col-md-2 mb-3 align-self-end">
									<button type="button" class="btn btn-primary w-100" @click="salvarProdutoTemp(null)"><i class="far fa-plus font-13"></i><span class="ms-1 d-none d-xl-inline"> Adicionar</span></button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-0 text-center" v-if="pesquisa.produtos.length == 0">
					<div class="card">
						<div class="card-body py-5" v-if="pesquisa.idOperacao == null">
							<i class="fal fa-stream font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-14 mt-4 mb-0 text-secondary text-uppercase">1º Passo</h4>
							<h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Selecione uma tipo de operação</h4>
						</div>
						<div class="card-body py-5" v-else-if="pesquisa.idLojaEntrada == null && pesquisa.idLojaSaida == null">
							<i class="fal fa-store font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-14 mt-4 mb-0 text-secondary text-uppercase">2º Passo</h4>
							<h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Selecione uma loja</h4>
						</div>
						<div class="card-body py-5" v-else>
							<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
						</div>
					</div>
				</div>

				<div class="col-12 px-0" v-if="pesquisa.produtos.length > 0">
					<produto v-for="(produto, index) in pesquisa.produtos" :key="index" :produto="produto" :index="index" @salvar="salvarProdutoTemp($event)" />
				</div>
			</div>
		</div>

		<impressao id="impressao" class="d-none" :titulo="$route.name.replace(/_/g, ' ').replace(/-/g, '/')" :data="new Date()" :cabecalho="{
			'Operação': pesquisa.idOperacao == null ? '-' : pesquisa.idOperacao.nome, 
			'Loja emitente': pesquisa.idLojaSaida == null ? '-' : dadosUsuario.usuarioLojas.find(l => l.id == pesquisa.idLojaSaida).nomeFantasia, 
			'Estoque saída': pesquisa.idEstoqueSaida == null ? '-' : dadosUsuario.usuarioLojas.find(l => l.id == pesquisa.idLojaSaida).estoques.find(e => e.id == pesquisa.idEstoqueSaida).nome, 
			'Loja destinatário': pesquisa.idLojaEntrada == null ? '-' : dadosUsuario.usuarioLojas.find(l => l.id == pesquisa.idLojaEntrada).nomeFantasia, 
			'Estoque entrada': pesquisa.idEstoqueEntrada == null ? '-' : dadosUsuario.usuarioLojas.find(l => l.id == pesquisa.idLojaEntrada).estoques.find(e => e.id == pesquisa.idEstoqueEntrada).nome
			}" :itensKey="{'nome': 'Descrição', 'quantidade': 'Qtd.', 'preco': 'Unitário'}" :itens="pesquisa.produtos" :total="pesquisa.valorTotal" />
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import produto from '@/components/produtos/ajusteEstoque/Produto.vue'
import autoCompleteProduto from '@/components/fiscal/AutoCompleteProduto.vue'

export default {
	name: 'Ajuste_de_estoque',
	data: function () {
      return {
         pesquisa: {"idOperacao": null, "idLojaEntrada": null, "idLojaSaida": null, "idEstoqueEntrada": null, "idEstoqueSaida": null, "observacoes": null, "produtos": [], 'valorTotal': '0.00'},
			pesquisaProduto: {'id': null, 'idProduto': null, 'nome': null, 'quantidade': 1, 'preco': '0.00'},
			estoques: {'entrada': [], 'saida': []}
		}
	},
	computed: {
      ... mapState({
			listaOperacoesEstoque: state => state.listaOperacoesEstoque,
			listaEstoques: state => state.listaEstoques,
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
			urlRest: state => state.urlRest
      })
   },
   watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
			this.buscarTemp()
      },
		'pesquisaProduto.quantidade': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 4}).then((valor) => {
            this.pesquisaProduto.quantidade = valor
         })
      },
      'pesquisaProduto.preco': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 4}).then((valor) => {
            this.pesquisaProduto.preco = valor
         })
      }
	},
	components: {
      produto, impressao, autoCompleteProduto
   },
	methods: {
		buscarEstoques : function (idLoja, tipoOperacao) {
			/* eslint-disable-next-line no-unused-vars */
			return new Promise((resolve, reject) => {
				let loja = this.dadosUsuario.usuarioLojas.find(l => l.id == idLoja)
	
				if (loja != null) {
					this.estoques[tipoOperacao] = loja.estoques
					
				} else {
					this.estoques[tipoOperacao] = []
	
					if (tipoOperacao == 'entrada') {
						this.pesquisa.idLojaEntrada = null
						this.pesquisa.idEstoqueEntrada = null
					} else {
						this.pesquisa.idLojaSaida = null
						this.pesquisa.idEstoqueSaida = null
					}
				}
	
				this.salvarTemp()
				resolve()
			})
		},
		buscarTemp : function () {			
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa, this.$options.data.apply(this).pesquisa)

			this.$axios({
				method: 'get',
				url: this.urlRest +'produtos/getEntradaSaidaTemp'
				
			}).then(response => {
				let lojaEntrada = this.dadosUsuario.usuarioLojas.find(l => l.id == response.data.idLojaEntrada)
				let lojaSaida = this.dadosUsuario.usuarioLojas.find(l => l.id == response.data.idLojaSaida)
				let estoqueEntrada = lojaEntrada == null ? null : lojaEntrada.estoques.find(e => e.id == response.data.idEstoqueEntrada)
				let estoqueSaida = lojaSaida == null ? null : lojaSaida.estoques.find(e => e.id == response.data.idEstoqueSaida)

				this.buscarEstoques(lojaEntrada == null ? null : lojaEntrada.id, 'entrada').then(() => {
					this.buscarEstoques(lojaSaida == null ? null : lojaSaida.id, 'saida').then(() => {
						this.pesquisa = {
							"idOperacao": this.listaOperacoesEstoque.find(o => o.id == response.data.idOperacao),
							"idLojaEntrada": lojaEntrada == null ? null : lojaEntrada.id,
							"idLojaSaida": lojaSaida == null ? null : lojaSaida.id,
							"idEstoqueEntrada": estoqueEntrada == null ? null : estoqueEntrada.id,
							"idEstoqueSaida": estoqueSaida == null ? null : estoqueSaida.id,
							"observacoes": response.data.observacoes,
							"produtos": response.data.produtos,
							'valorTotal': response.data.produtos.reduce((total, p) => total + (parseFloat(p.quantidade) * parseFloat(p.preco)), 0)
						}
					})
				})
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
		salvarTemp : function () {
			if (this.pesquisa.idOperacao) {
				this.$store.dispatch('alternarTelaCarregamento', true)

				let envio = JSON.parse(JSON.stringify(this.pesquisa))
				envio.idOperacao = envio.idOperacao.id

				this.$axios({
					method: 'post',
					url: this.urlRest +'produtos/saveEntradaSaidaTemp',
					headers: {'Content-Type': 'application/json'},
					data: envio

				}).finally(() => {
					this.$store.dispatch('alternarTelaCarregamento', false)
				});
			}
		},
		setProdutoTemp : function (result) {
			this.pesquisaProduto = {'id': null, 'idProduto': result.id, 'nome': result.nome, 'quantidade': 1, 'preco': result.custo == null ? "0.00" : parseFloat(result.custo).toFixed(2)}
			this.$refs.pesquisaProdutoQtd.focus()
		},
		salvarProdutoTemp : function (produto) {
			if (produto == null) produto = this.pesquisaProduto;

			if (produto.idProduto == null) return;

			if (!/^[0-9.]+$/.test(produto.quantidade) || !/^[0-9.]+$/.test(produto.preco)) {
				this.$toast.fire({
					icon: 'error',
					title: 'Quantidade/preço unitário inválido'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/saveEntradaSaidaProdutoTemp',
				headers: {'Content-Type': 'application/json'},
				data: produto

			}).then(() => {
				Object.assign(this.$data.pesquisaProduto, this.$options.data.apply(this).pesquisaProduto)
				this.buscarTemp()

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		excluirTemp : function () {
			Swal.fire({
            icon: 'warning',
            title: 'Descartar operação?',
            text: 'Todas informações preenchidas até o momento serão descartadas.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`
				
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'produtos/deleteEntradaSaidaTemp'
						
					}).then(() => {
						this.buscarTemp()
		
						this.$toast.fire({
							icon: 'success',
							title: 'Operação descartada!'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		},
		emitirEntradaSaida : function () {
			if ((this.pesquisa.idOperacao.apenasEntrada && (this.pesquisa.idLojaEntrada == null || this.pesquisa.idEstoqueEntrada == null)) ||
				(this.pesquisa.idOperacao.apenasSaida && (this.pesquisa.idLojaSaida == null || this.pesquisa.idEstoqueSaida == null))) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			Swal.fire({
            icon: 'warning',
            title: ('Emitir '+ (this.pesquisa.idOperacao.apenasEntrada ? 'entrada' : this.pesquisa.idOperacao.apenasSaida ? 'saída' : 'entrada/saída') +' de estoque?'),
            text: 'Essa ação é irreversível, caso necessário, adicione observações para registro abaixo',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
				input: 'textarea',
				inputPlaceholder: 'Observações',
            inputValidator: () => {
               if (this.pesquisa.produtos.filter(p => !/^[0-9.]+$/.test(p.quantidade) || !/^[0-9.]+$/.test(p.preco)).length > 0) {
                  return 'Existem produtos com quantidade/preço unitário inválidos!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'produtos/emitirEntradaSaida',
						headers: {'Content-Type': 'application/json'},
						data: {'observacoes': result.value}
						
					}).then(() => {
						Swal.fire({
							icon: 'success',
							title: 'Operação realizada!',
							text: 'Imprimir comprovante?',
							showCancelButton: true,
							confirmButtonText: 'Sim',
							cancelButtonText: `Não`
							
						}).then((result) => {
							if (result.isConfirmed) {
								setTimeout(() => this.$htmlToPaper('impressao', {styles: ['/comprovanteA4.css']}), 250);
								setTimeout(() => this.buscarTemp(), 500);
							}
						})
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		}
	},
   mounted() {
		this.$store.dispatch('getOperacoesEstoque')
		this.buscarTemp()
   }
}

</script>

<style scoped>

#estoque-tab .card .row button {
	line-height: 1;
	padding: 12px 6px;
	text-align: center;
	display: block;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 12px;
	width: 100%;
	border-radius: 3px;
}

@media (min-width: 992px) {
   #estoque-tab {
      width: 250px;
   }
}

@media (min-width: 1400px) {
   #estoque-tab {
      width: 305px;
   }
}

</style>