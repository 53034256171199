<template>
   <div class="col-12 px-0 mb-1" ref="produto">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="w-max-content pe-12 d-none d-xxl-block">
                  <img :src="produto.banner == null ? '' : produto.banner" class="rounded" alt="icon" @error="imageError" height="50" />
               </div>
               <div class="col-md text-center text-md-start">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ produto.nome }}</h1>
                        <p class="font-10 mb-0 mb-lg-2 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ produto.id }}</span>
                           <span class="ms-3">
                              <i class="far fa-tags font-9 me-1"></i>
                              <span>{{ produto.familia == null || String(produto.familia).trim().length == 0 ? 'Sem família' : produto.familia }}</span>
                           </span>
                        </p>
                     </div>
                     <div class="col d-none d-lg-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-barcode color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Código(s):</strong>
                           <span> {{ produto.codigo == null || String(produto.codigo).trim().length == 0 ? 'Sem código' : produto.codigo }}</span>
                        </p>
                     </div>
                     <div class="col-5 d-none d-xl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-money-bill-wave-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-xl-inline"> Total:</strong>
                           <span><small>R$ </small> {{ (parseFloat(produto.quantidade) * parseFloat(produto.preco)).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <label><i class="far fa-box color-theme font-10 me-1"></i> Quantidade</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="produto.quantidade" />
               </div>
               <div class="w-max-content text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Preço Un.</label>
                  <input type="text" class="form-control input-sm wpx-115" v-model="produto.preco" />
               </div>
               <div class="w-max-content text-center font-16 mx-auto me-md-0 ms-md-3 mt-2 mt-md-0">
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Produto',
   props: ['produto', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   watch: {
      'produto.quantidade': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 4}).then((valor) => {
            this.produto.quantidade = valor
            if (String(valor).length > 0) this.$emit('salvar', this.produto)
         })
      },
      'produto.preco': function (newVal) {
         this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 4}).then((valor) => {
            this.produto.preco = valor
            if (String(valor).length > 0) this.$emit('salvar', this.produto)
         })
      }
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Remover produto?',
            text: 'O produto será removido da operação atual.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'produtos/deleteEntradaSaidaProdutoTemp',
                  params: {
                     id: this.produto.id
                  }
               }).then(() => {
                  this.$refs.produto.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Produto removido!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>